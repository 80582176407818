import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container, Columns, Column, Content, Title, Section } from 'bloomer'

export default () => (
  <Layout>
    <SEO title="Grooming Agreement" alias="grooming-agreement" />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Title tag="h1" hasTextAlign="centered">
              Grooming Agreement
            </Title>
            <Content>
              <p style={{ 'text-align': 'justify' }}>
                The following is a copy of the standard grooming agreement we
                require all of our grooming customers to sign. Blank fields have
                been replaced with [ bracketed ] place-holders.
              </p>
              <p style={{ 'text-align': 'center' }}>
                <strong>BETWEEN: THIS AGREEMENT</strong> is made this [ day ]
                day of [ month ], [ year ]
              </p>
              <p style={{ 'text-align': 'center' }}>
                <strong>SAMUEL HEWITT and JUDY HEWITT, Jointly</strong> – of the
                Township of Huron-Kinloss, in the County of Bruce
                <br />
                Hereinafter called “Hewitts”, OF THE FIRST PART
              </p>
              <p style={{ 'text-align': 'center' }}>-and-</p>
              <p style={{ 'text-align': 'center' }}>[ pet owner ]</p>
              <p style={{ 'text-align': 'center' }}>
                of the Township of [ township ], in the County of [ county ],
                <br />
                Hereinafter called “the Pet Owner” OF THE SECOND PART
                <br />
                <strong>WHEREAS</strong> THE Hewitts operate a pet boarding
                kennel at part of lots 51 and 52, Concession 1<br />
                in the Township of Huron-Kinloss, in the County of Bruce shown
                as Part 1 on Plan 3R-7090
                <br />
                <strong>AND WHEREAS</strong> the Pet Owner wishes to groom a dog
              </p>
              <p>
                <strong>AND WHEREAS</strong> the Hewitts wish to clearly limit
                the extend of their liability as set out in this agreement.
                <br />
                <strong>NOW THEREFORE IN CONSIDERATION OF</strong> the terms and
                conditions contained herein and other good and valuable
                consideration the parties hereto agree as follows:
              </p>
              <ol>
                <li>
                  By signing the agreement; the Pet Owner verifies the pet is
                  current on rabies vaccination. Proof of vaccination shall be
                  provided to the Hewitts on request.
                </li>
                <li>
                  The Pet Owner must inform the Hewitts if the pet has bitten or
                  shown aggression to people during previous grooming
                  procedures. Muzzles may be used to protect both the pet and
                  the groomer.
                </li>
                <li>
                  During matt removal extra attention is required. There is a
                  great risk of nicks, cuts and abrasion. Urine and moisture can
                  be trapped near the pet’s skin, causing irritations. Hair may
                  not regrow the same after matt removal procedures. The Hewiits
                  will not continues to de-matt your pet. The procedure is
                  stressful; prevention is the best defence.
                </li>
                <li>
                  Grooming equipment is sharp. Nicks &amp; cuts, scratches and
                  quicking of nails may occur during procedures.
                </li>
                <li>
                  Pets infested with fleas receive flea bath at extra charge.
                  Ticks will be removed at an additional charge.
                </li>
                <li>
                  It is expressly acknowledged and agreed that the Hewitts shall
                  not be held liable for any claim or damages by the Pet Owner
                  in the event of the death of the pet or in the event that the
                  pet becomes ill, or incapacitated in any way during their stay
                  at the Hewitts or after their departure. It is the intent that
                  the pet is left with the Hewitts at the sole risk of the Pet
                  Owner.
                </li>
                <li>
                  In the event that the pet requires veterinary examination
                  and/or treatment in the opinion of the Hewitts during the
                  grooming period at the Hewitts, the Hewitts shall have the
                  right to engage the services of a veterinarian as they deem
                  reasonable for the benefit of the pet and the Pet Owner shall
                  be responsible for and does hereby agree to pay for the
                  veterinarian’s fees and charges for medication or related
                  materials.
                </li>
                <li>
                  In the event that the pet is/are unclaimed after the agreed,
                  above, board ending date, the Hewitts reserve the right to
                  give over the pet to the person or persons of their choice,
                  due to the failure of the pet owner to fully comply with the
                  above agreement, and the Hewitts will not then be held liable,
                  at any future date.
                </li>
                <li>
                  Grooming procedures may be altered or ceased in the event that
                  the groomer determines it is the best interest of the Pet
                  Owner’s pet or if the Pet Owner’s pet displays aggressive
                  behaviours. The Hewitts have the right to refuse service to
                  the Pet Owner at any time for any reason.
                </li>
                <li>
                  Boarding fees will be applied if pet is not picked up on date
                  of grooming procedure.
                </li>
              </ol>
            </Content>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)
